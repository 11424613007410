import ApiService from "./ApiService"

export async function apiMetadatasGetQuery (search) {
    return ApiService.fetchData({
        url: '/metadatas/query',
        method: 'get',
        params : {query : search}
    })
}

export async function apiMetadatasGetAll() {
    return ApiService.fetchData({
        url: '/metadatas/all',
        method: 'get'
    })
}





