import { combineReducers } from '@reduxjs/toolkit'
import supplier from './supplierSlice'
import events from './eventsSlice'
import metadatas from './metadatasSlice'
import contract from './contractSlice'
import notifications from './notificationsSlice'

const reducer = combineReducers({
    supplier,
    metadatas,
    events,
    contract, 
    notifications
})

export default reducer
