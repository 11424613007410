
import ApiService from "../ApiService"

export async function apiGetAllEvents() {
    return ApiService.fetchData({
        url: '/agenda/events',
        method: 'get'
    })
}

export async function apiGetAllEventsByClient(id) {
    return ApiService.fetchData({
        url: `/agenda/events/${id}/client`,
        method: 'get'
    })
}


export async function apiAddEvent(datas) {
    return ApiService.fetchData({
        url: `/agenda/events/new`,
        method: 'post',
        data : {datas: datas}
    }) 
}

export async function apiAddClientEvent(datas) {
    return ApiService.fetchData({
        url: `/agenda/events/client`,
        method: 'post',
        data : {datas: datas}
    })
}

export async function apiUpdateEvent(datas) {
    return ApiService.fetchData({
        url: `/agenda/events/update`,
        method: 'post',
        data : {datas: datas}
    })
}

export async function apiUpdateFieldsEvent(datas) {
    return ApiService.fetchData({
        url: `/agenda/events/update-fields`,
        method: 'post',
        data : {datas: datas}
    })
}

export async function apiDeleteEvent(id) {
    return ApiService.fetchData({
        url: `/agenda/events/${id}/delete`,
        method: 'post'
    })
}
