import ApiService from "./ApiService"



export async function apiGetContracts(params) {
    return ApiService.fetchData({
        url: '/contracts',
        method: 'get',
        params: params
    })
}

export async function apiGetContractsQuery(params) {
    return ApiService.fetchData({
        url: '/contracts/query',
        method: 'get',
        params: params
    })
}

export async function apiGetContractsTable(params) {
    return ApiService.fetchData({
        url: '/contracts/query',
        method: 'get',
        params: params
    })
}

export async function apiGetContract (params) {
    return ApiService.fetchData({
        url: '/contracts/' + params.id,
        method: 'get',
        params: params
    })

}

export async function apiCreateContract (data) {
    return ApiService.fetchData({
        url: '/contracts/new',
        method: 'post',
        data
    })
}



export async function apiPutContract (data) {
    return ApiService.fetchData({
        url: '/contracts/' + data.id + '/update', 
        method: 'post',
        data
    })
}



export async function apiDeleteContract (data) {
    return ApiService.fetchData({
        url: '/contracts/' + data.id + '/delete', 
        method: 'delete',
        data
    })
}

