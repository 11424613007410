import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetContracts, apiGetContractsQuery } from 'services/ContractsService'

const getContractQuery = createAsyncThunk('shared/contract/getContract', async (data) => {
    const response = await apiGetContractsQuery(data)
    return response.data
})


export const getContracts = createAsyncThunk('shared/contract/getContracts', async (data) => {
    const response = await apiGetContracts("")
    return response.data
    
})



export const initialState = {
    loading: false,
    contracts : []
}


export const contractSlice = createSlice({
	name: 'shared/contract',
	initialState,
	reducers: {
        
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
        setFilterData: (state, action) => {
            state.filterData = action.payload
        },
	},
    extraReducers: {
        [getContracts.fulfilled]: (state, action) => {
            state.contracts = action.payload;
            state.loading = false;
        },
        [getContracts.pending]: (state, action) => {
            state.loading = true;
        },
    }
})

export const {  } = contractSlice.actions

export default contractSlice.reducer