import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetNotificationCount, apiGetNotificationList } from 'services/CommonService'
import { apiMetadatasGetAll, apiMetadatasGetQuery } from 'services/MetadatasService'

export const getNotificationCount = createAsyncThunk('shared/notifications/getNotificationCount', async (data) => {
    const response = await apiGetNotificationCount(data)
    return response.data
})


export const getNotificationList = createAsyncThunk('shared/notifications/getNotificationList', async (data) => {
    const response = await apiGetNotificationList()
    return response.data
    
})



export const initialState = {
    loading: false,
    count: 0,
    data : {
        client_types: [],
        suppliers: [],
    }
}


export const notificationsSlice = createSlice({
	name: 'shared/notifications',
	initialState,
	reducers: {
        
	},
    extraReducers: {
        [getNotificationList.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.loading = false;
        },
        [getNotificationList.pending]: (state, action) => {
            state.loading = true;
        },
        [getNotificationCount.fulfilled]: (state, action) => {
            state.count = action.payload;
        },
    }
})

export const {  } = notificationsSlice.actions

export default notificationsSlice.reducer